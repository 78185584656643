<template>
    <div>
        <h3><span v-if="infoPaymentMethods.length==0"> There are not payment methods </span></h3>
        <table small caption-top responsive style="font-size: small" id="table" class="table b-table">
            <thead>
                <th>Card</th>
                <th>Last 4</th>
                <th>Expired month</th>
                <th>Expired year</th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="item in listPaymentMethods">
                    <td>{{ item.brand }}</td>
                    <td>{{ item.last4 }}</td>
                    <td>{{ item.exp_month }}</td>
                    <td>{{ item.exp_year }}</td>
                    <td>
                        <b-form-radio name="radios-automatic-price" v-model="selectedPaymentMethod"
                        :change="setSeletedPaymentMethod(selectedPaymentMethod)"
                            :value=item.stripe_payment_method_id></b-form-radio>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormRadio,
} from "bootstrap-vue";
import { getMessageError } from "@core/utils/utils";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BFormSelect,
        BFormRadio,
    },
    props: {
        proPaymentsMethods: null,
        idCustomerPaymentMehtod: null,
        isSelfpay: null
    },
    data() {
        return {
            infoPaymentMethods: [],
            listPaymentMethods: [],
            client: 0,
            selectedPaymentMethod: null,
        }
    },
    methods: {
        async getCard(id, paymentMethodId, isSelfpay) {


            if (paymentMethodId == 0) {
                return false;
            }

            let url = `admin/panel/ca/${id}/${paymentMethodId}/get_ca_credit_card_by_caid_and_payment_id`;

            if (isSelfpay == true) {
                url = `admin/panel/selfpaid/${id}/${paymentMethodId}/get_credit_card_by_selfpaid_id_and_payment_method_id`
            }
            await this.$http
                .get(url)
                .then((response) => {
                    if (response.data.data) {

                        let element = this.listPaymentMethods.find(x => x.stripe_payment_method_id == paymentMethodId);
                        element.exp_month = response.data.data['exp_month'];
                        element.exp_year = response.data.data['exp_year'];
                        element.brand = response.data.data['brand'];
                        element.last4 = response.data.data['last4'];
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        createListPaymentMethods() {
            this.listPaymentMethods = [];
            for (let index = 0; index < this.infoPaymentMethods.length; index++) {
                let element = this.infoPaymentMethods[index];
                let data = JSON.parse('{"stripe_payment_method_id":null, "is_default":null, "exp_month":"Loading...", "exp_year":"Loading...", "brand":"Loading...", "last4":"Loading..." }');
                data.stripe_payment_method_id = element.stripe_payment_method_id;
                data.is_default = element.is_default;
                this.listPaymentMethods.push(data);
            }
        },
        async loadCards() {
            for (let index = 0; index < this.listPaymentMethods.length; index++) {
                let element = this.listPaymentMethods[index];
                if (element.is_default === 1) {
                    this.selectedPaymentMethod = element.stripe_payment_method_id;
                }
                await this.getCard(this.idCustomerPaymentMehtod, element.stripe_payment_method_id, this.isSelfpay);
            }
        },
        async loadInfo() {
            this.infoPaymentMethods = this.proPaymentsMethods;
            this.createListPaymentMethods();
            await this.loadCards();
        },
        setSeletedPaymentMethod(paymentMethod)
        {
            this.$emit("setSeletedPaymentMethod",paymentMethod);
        }
    },
    mounted() {
        this.loadInfo();
    },
};
</script>

<style></style>