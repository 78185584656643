<template>
    <div v-if="this.listPaymentMethods" style="margin-top: 10px;">
        <h2 v-if="proShowEditPaymentMethod==false">Payments Methods</h2>
        <hr style="  border-top: 2px solid;color: #d7cbcb;"  v-if="proShowEditPaymentMethod==true">

       
        <b-row v-if="isEditPaymentMethod == false && proShowEditPaymentMethod==true" class="mt-2">
            <b-col cols="2">
                <span>Current type payment</span>
            </b-col>
            <b-col cols="2">
                <b-form-input style="margin-top: -8px;" cols="4" :value="getPaymentMethod(paymentType)" disabled />
            </b-col>
            <b-col cols="3">
                <b-button @click="setEditPaymentMethod()" variant="primary" class="ml-2" style="margin-top: -8px;">
                        Edit type payment
                    </b-button>
            </b-col>
        </b-row>

        <b-col md="12" v-if="isEditPaymentMethod == true">
            <b-form-group label="">
                <div style="display:flex" v-if="paymentType">
                    <span style="margin-top:-2px;margin-right: 20px;font-size: larger;font-weight: 600;">Chose the
                        payment
                        method</span>
                    <b-form-radio v-model="paymentType" name="radios-type-payment" value="cc">Credit card</b-form-radio>
                    <b-form-radio v-model="paymentType" class="ml-2" name="radios-type-payment" value="il">Invoice
                        later</b-form-radio>

                    <b-button @click="setPaymentTypeCorporateAccount()" variant="primary" class="ml-2"
                        style="margin-top: -8px;">
                        Save
                        <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                    </b-button>

                    <b-button @click="cancelEditPaymentMethod()" variant="danger" class="ml-2"
                        style="margin-top: -8px;">
                        Cancel
                    </b-button>

                </div>
            </b-form-group>
        </b-col>
        <hr style="  border-top: 2px solid;color: #d7cbcb;">
        <template v-for="payment in listPaymentMethods" v-if="paymentType == 'cc'">

            <CaPaymentMethodCreditCard v-if="renderCompont"
                v-on:updateDefaultPaymentMethod="updateDefaultPaymentMethod($event)"
                 v-on:updatePaymentMethod="updatePaymentMethod($event)"
                v-on:addEmptyPaymentMethod="addEmptyPaymentMethod()"
                v-on:deletePaymentMethod="deletePaymentMethod($event)" :isDefault="payment.is_default"
                :paymentMethodId="payment.stripe_payment_method_id" :id="payment.id" :proStripeCustomerId="stripeCustomerId"
                :proPaymentType="paymentType" :proCaId="caId">
            </CaPaymentMethodCreditCard>
            <hr style="  border-top: 2px solid;color: #d7cbcb;">
        </template>

        <template v-if="paymentType == 'il'">
            <CaPaymentMethodInvoiceLater  :paymentMethodId="-1" 
            :proPaymentType="paymentType" :proInvoiceLaterNote="invoiceLaterNote">
            </CaPaymentMethodInvoiceLater>
        </template>
    </div>
</template>

<script>
import CaPaymentMethodInvoiceLater from '@/@core/components/CA/CaPaymentMethod.vue'
import CaPaymentMethodCreditCard from '@/@core/components/CA/CaPaymentMethod.vue'
import { getMessageError } from "@core/utils/utils";

import {
    BButton, BFormRadio,BRow, BCol, BFormGroup, BFormInput
} from 'bootstrap-vue'

export default {
    components: {
        CaPaymentMethodCreditCard,
        CaPaymentMethodInvoiceLater,
        BButton,
        BFormRadio,
        BRow,
        BCol,
        BFormGroup,
        BFormInput
    },
    props: {
        proPaymentType: null,
        proListPaymentMethods:[],
        proStripeCustomerId:null,
        proCaid:null,
        proInvoiceLaterNote:null,
        proShowEditPaymentMethod:true,
    },
    data() {
        return {
            renderCompont: true,
            isEditPaymentMethod: false,
            oldPaymentData: "",
            paymentType: null,
            listPaymentMethods:[],
            stripeCustomerId: null,
            caId:null,
            invoiceLaterNote:null,
        }
    },
    methods: {
        async updateDefaultPaymentMethod(values) {
           let caId=values.caId;
           let paymentId=values.paymentId;
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });

            let formData = new FormData();
            formData.append("id", caId);
            formData.append("paymentId", paymentId);

            await this.$http
                .post("admin/panel/set_defaulf_payment_corporate_account", formData)
                .then((response) => {
                    this.$swal().close();
                    for (let index = 0; index < this.listPaymentMethods.length; index++) {
                        let element = this.listPaymentMethods[index];
                        element.is_default = 0;
                    }

                    let data = this.listPaymentMethods.find(x => x.stripe_payment_method_id == paymentId);
                    if (data) {
                        data.is_default = 1;
                    }

                }).catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        updatePaymentMethod(paymentId) {
            let data = this.listPaymentMethods.find(x => x.stripe_payment_method_id == 0);
            if (data) {
                data.stripe_payment_method_id = paymentId;
            }
            this.$emit("updatePaymentMethodList",false);
        },
        addEmptyPaymentMethod() {
            let isFindAdd = this.listPaymentMethods.find(x => x.stripe_payment_method_id == 0);

            if(isFindAdd==null)
            {
                let data = JSON.parse('{ "stripe_payment_method_id": 0, "is_default": 0 }');
                this.listPaymentMethods.push(data);
            }

        },
        setDefault(paymentMethodId) {
            let data = this.listPaymentMethods.find(x => x.PaymentMethodId == paymentMethodId);
            data.is_default = 1;
        },
        deletePaymentMethod(paymentId) {
            this.listPaymentMethods = this.listPaymentMethods.filter(x => x.stripe_payment_method_id != paymentId)
            this.renderCompont = false;
            this.$nextTick().then(() => {
                this.renderCompont = true;
            });
            this.$emit("updatePaymentMethodList",false);
        },
        setPaymentTypeCorporateAccount() {

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });

            let formData = new FormData();
            formData.append("id", this.caId);
            formData.append("payment_type", this.paymentType);

            this.$http
                .post("admin/panel/set_payment_type_corporate_account", formData)
                .then((response) => {
                    this.$swal().close();
                    this.isEditPaymentMethod = false;
                    this.oldPaymentData = this.paymentType;
                }).catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })
        },
        removeAddElement() {
            let indexElementToRemove = this.listPaymentMethods.findIndex(x => x.stripe_payment_method_id === 0);
            if (indexElementToRemove > 0) {
                this.listPaymentMethods.splice(indexElementToRemove, 1);
            }
            if (this.paymentType == "cc") {
                this.addEmptyPaymentMethod();
            }
        },
        getPaymentMethod(data) {
            let res = "";
            try {
                if (data == "cc") {
                    res = "Credit card";
                }
                if (data == "il") {
                    res = "Invoice later";
                }
            } catch (error) {

            }
            return res;
        },
        setEditPaymentMethod() {
            this.isEditPaymentMethod = true;
        },
        cancelEditPaymentMethod() {
            this.isEditPaymentMethod = false;
            this.paymentType = this.oldPaymentData;
        }
    },
    mounted() {
        this.oldPaymentData =this.proPaymentType;
        this.paymentType = this.proPaymentType;
        this.stripeCustomerId=this.proStripeCustomerId;
        this.caId=this.proCaid;
        this.invoiceLaterNote=this.proInvoiceLaterNote;
        this.listPaymentMethods=this.proListPaymentMethods;  
 
        this.addEmptyPaymentMethod();
    }
}
</script>

<style></style>
