<template>
  <div>
    <BookARide></BookARide>
  </div>
</template>

<script>
import BookARide from '@core/components/FormsRegister/BookARide'

export default {
  components: {
    BookARide,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
